import React from 'react'

const DeckCard = ({ deck }) => {

  if (!deck) {
    console.warn('DeckCard: No deck prop received')
    return null
  }

  return (
    <div className="deckCard">
      <div 
        className="text-center relative bg-white rounded-3xl flex flex-col justify-between"
        style={{ width: '358px', height: '100%', border: '1px', borderColor: deck.cardBg, borderStyle: 'solid' }}
      >
        <div class="flex flex-row justify-center items-center h-full px-16">
          <img src={deck.imagePath} alt={deck.title} className="w-full h-auto" />
        </div>
        <div 
          className="deckCard__back flex flex-col items-center justify-center p-8 rounded-b-3xl h-1_2"
          style={{ color: deck.textColor, backgroundColor: deck.cardBg, height: '50%' }}
        >
                    

          <h2 className="font-bold text-2xl mb-4">{deck.title}</h2>
          <p>{deck.description}</p>
        </div>
      </div>
    </div>
  )
}

export default DeckCard
