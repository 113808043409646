import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["progress", "loading", "timeout", "error"]
  static values = {
    conversationId: String,
    checkInterval: { type: Number, default: 2000 },
    timeout: { type: Number, default: 60000 }
  }

  connect() {
    this.startPolling()
    this.startTimeout()
    this.progressValue = 0
    this.updateProgressBar()
  }

  disconnect() {
    this.stopPolling()
    if (this.timeoutId) clearTimeout(this.timeoutId)
  }

  startPolling() {
    this.checkStatus()
    this.pollId = setInterval(() => {
      this.checkStatus()
      this.progressValue = Math.min(this.progressValue + 5, 100)
      this.updateProgressBar()
    }, this.checkIntervalValue)
  }

  stopPolling() {
    if (this.pollId) clearInterval(this.pollId)
  }

  startTimeout() {
    this.timeoutId = setTimeout(() => {
      this.stopPolling()
      this.showTimeout()
    }, this.timeoutValue)
  }

  async checkStatus() {
    try {
      const response = await fetch(`/conversations/${this.conversationIdValue}/results`)
      const data = await response.json()

      if (data.status === 'complete') {
        this.stopPolling()
        window.location.href = `/conversations/${this.conversationIdValue}`
      }
    } catch (error) {
      this.showError()
    }
  }

  showTimeout() {
    this.loadingTarget.classList.add('hidden')
    this.timeoutTarget.classList.remove('hidden')
  }

  showError() {
    this.stopPolling()
    this.loadingTarget.classList.add('hidden')
    this.errorTarget.classList.remove('hidden')
  }

  updateProgressBar() {
    this.progressTarget.style.width = `${this.progressValue}%`
  }

  retry() {
    this.errorTarget.classList.add('hidden')
    this.timeoutTarget.classList.add('hidden')
    this.loadingTarget.classList.remove('hidden')
    this.progressValue = 0
    this.updateProgressBar()
    this.startPolling()
    this.startTimeout()
  }
}