import { Controller } from "@hotwired/stimulus"

/**
 * Stimulus controller for handling card swipe interactions
 * Implements Tinder-like swipe functionality with infinite loop and keyboard support
 * Added flippable card functionality for cards with is_flippable=true
 */
export default class extends Controller {
  static targets = ["swipeContainer", "subscriptionPrompt", "previewModePrompt"]
  static values = { 
    cards: Array,
    favorites: Array,
    chapterColor: String,
    initialCardId: String,
    previewMode: Boolean
  }

  connect() {   
    if (!this.hasCardsValue) {
      console.warn('No cards value provided')
    }

    // Store original cards data for infinite loop
    this.originalCards = [...this.cardsValue]
    
    // Initialize keyboard listener
    this.initializeKeyboardControls()
    
    this.initializeCards()

    // Add heart button click listener
    this.initializeHeartButton()

    // Debug logging
    console.log('Has initial card id?', this.hasInitialCardIdValue)
    console.log('Initial card id value:', this.initialCardIdValue)
    console.log('Raw data attribute:', this.element.dataset.swipeInitialCardIdValue)

    // Handle initial card positioning if card_id is present
    if (this.hasInitialCardIdValue) {
      console.log('Moving to card:', this.initialCardIdValue)
      this.moveToCard(this.initialCardIdValue)
    }

    // Initialize preview mode prompt
    this.previewModePrompt = document.getElementById('previewModePrompt');
    if (this.previewModeValue) {
      this.initializePreviewMode();
    }
  }

  /**
   * Set up keyboard controls for swiping
   */
  initializeKeyboardControls() {
    document.addEventListener('keydown', (event) => {
      const currentCard = this.getCurrentCard()
      if (!currentCard) return

      switch (event.key.toLowerCase()) {
        case 'k':
          // Simulate a right swipe when 'K' is pressed
          this.dismissCard(currentCard, this.swipeContainerTarget.offsetWidth, 0)
          this.handleCardAction(currentCard, 'like')
          break
        case 'f':
          // Trigger heart button click to get animations
          const heartButton = document.getElementById('heartButton')
          if (heartButton) {
            heartButton.click()
          }
          break
      }
    })
    // Add aria-label to container for screen readers
    this.swipeContainerTarget.setAttribute('aria-label', 
      'Card stack. Use K key to like a card, F key to favorite it. ' +
      'Cards can also be swiped right to like or use the heart button to favorite.'
    )
  }

  getCurrentCard() {
    const cards = Array.from(this.swipeContainerTarget.querySelectorAll('.card'))
    return cards.sort((a, b) => {
      const aZ = parseInt(a.style.zIndex) || 0
      const bZ = parseInt(b.style.zIndex) || 0
      return bZ - aZ
    })[0]
  }

  initializeCards() {
    if (!this.cardsValue || !Array.isArray(this.cardsValue)) {
      console.warn('Cards value is not properly initialized')
      return
    }

    if (!this.hasSwipeContainerTarget) {
      console.error('No swipe container target found')
      return
    }

    // Clear existing cards
    this.swipeContainerTarget.innerHTML = ''

    // Create and initialize each card
    this.cardsValue.forEach((cardData, index) => {
      const card = this.createCard(cardData, index)
      this.swipeContainerTarget.appendChild(card)
      this.initializeDragListeners(card)
      if (cardData.is_flippable) {
        this.initializeFlipListeners(card)
      }
    })
  }

  createCard(cardData, index) {
    const card = document.createElement('div')
    card.className = 'card'
    card.style.zIndex = this.cardsValue.length - index
    card.dataset.cardId = cardData.id
    
    // Add flip-related classes and data attributes if card is flippable
    if (cardData.is_flippable) {
      card.classList.add('flippable')
      card.dataset.flipped = 'false'
    }

    // Create the inner container for flip animation
    const innerContainer = document.createElement('div')
    innerContainer.className = 'card-inner'
    
    // Create front side
    const frontSide = document.createElement('div')
    frontSide.className = 'card-side card-front'
    frontSide.style.background = `linear-gradient(140deg, 
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 100%
    ), ${this.chapterColorValue || '#ffffff'}`
    
    frontSide.innerHTML = `
      <div class="card-content ${cardData.template_type}-template">
        ${this.renderCardContent(cardData)}
      </div>
      ${cardData.is_flippable ? `<img src="${window.assetPaths.images.flipButton}" class="flip-button" alt="Flip card">` : ''}
    `

    // Create back side if card is flippable
    if (cardData.is_flippable) {
      const backSide = document.createElement('div')
      backSide.className = 'card-side card-back'
      backSide.style.background = frontSide.style.background
      backSide.innerHTML = `
        <div class="card-content">
          <p>${cardData.back_content.text_content}</p>
        </div>
        <img src="${window.assetPaths.images.flipButton}" class="flip-button" alt="Flip card">
      `
      innerContainer.appendChild(backSide)
    }

    innerContainer.appendChild(frontSide)
    card.appendChild(innerContainer)
    return card
  }

  initializeFlipListeners(card) {
    const flipButtons = card.querySelectorAll('.flip-button')
    flipButtons.forEach(button => {
      button.addEventListener('click', (e) => {
        e.stopPropagation() // Prevent event from bubbling to drag handlers
        this.flipCard(card)
      })
    })
  }

  flipCard(card) {
    // Only allow flipping if card is in rested state (not being dragged)
    if (card.classList.contains('dragging')) {
      return
    }

    const isFlipped = card.dataset.flipped === 'true'
    card.dataset.flipped = (!isFlipped).toString()
    card.querySelector('.card-inner').style.transform = isFlipped ? 'rotateY(0deg)' : 'rotateY(180deg)'
  }

  renderCardContent(cardData) {
    const templates = {
      basic: (data) => `
          ${data.front_content.title ? `
            <h3 style="margin-bottom: 10px; text-align: center; font-weight: bold;">
              ${data.front_content.title}
            </h3>
          ` : ''}
          <p style="font-size: 16px; text-align: center;">
            ${data.front_content.text}
          </p>
          ${data.front_content.note ? `
            <p style="font-size: 14px; font-style: italic; margin-top: 10px; text-align: center;">
              ${data.front_content.note}
            </p>
          ` : ''}
      `,

      image: (data) => {
        const normalizedTitle = data.front_content.title
          .toLowerCase()
          .replace(/[\u2018\u2019\u201C\u201D'',\-\.`"]/g, '')
          .replace(/\s+/g, '_')
          .trim();

        return `
          <h3 style="margin-bottom: 10px; text-align: left; font-weight: bold;">
            ${data.front_content.title}
          </h3>
          
          <div style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center;">
            <img 
              src="${window.assetPaths.images[normalizedTitle]}"
              alt="${data.front_content.title}"
              style="height: auto; max-height: 200px; width:100%; max-width: 100%;"
            />
          </div>

          <p style="font-size: 14px; text-align: left;">
            ${data.front_content.text}
          </p>
        `
      },

      featured: (data) => `
        <div class="featured-banner bg-yellow-500 text-white px-2 py-1 rounded-tr-lg rounded-bl-lg 
                    absolute top-0 right-0">
          Featured
        </div>
        <div class="card-image mb-4">
          <img src="${data.image_url}" alt="${data.image_alt || ''}" 
               class="w-full h-64 object-cover rounded-lg">
        </div>
        <h3 class="text-xl font-bold mb-2">${data.title}</h3>
        <p class="mb-4">${data.content}</p>
        ${data.cta_button ? `
          <button class="bg-blue-500 text-white px-4 py-2 rounded-lg w-full">
            ${data.cta_button}
          </button>
        ` : ''}
      `
    }

    const templateFn = templates[cardData.template_type] || templates.basic

    if (!this.validateCardData(cardData)) {
      console.error(`Invalid card data for template type: ${cardData.template_type}`)
      return templates.basic(cardData)
    }

    return templateFn(cardData)
  }

  validateCardData(cardData) {
    const requiredFields = {
      basic: ['text'],
      image: ['title', 'text'],
      featured: ['title', 'content', 'image_url']
    }

    const templateType = cardData.template_type || 'basic'
    const fields = requiredFields[templateType] || requiredFields.basic
    
    return fields.every(field => 
      cardData.front_content && cardData.front_content[field] != null
    )
  }

  initializeDragListeners(card) {
    let isDragging = false
    let startX = 0
    let startY = 0
    let translateX = 0
    let translateY = 0
    let dragStartTime = 0  // Added this variable

    const onDragStart = (e) => {
      // Don't initiate drag if clicking flip button
      if (e.target.classList.contains('flip-button')) {
        return
      }

      isDragging = true
      dragStartTime = Date.now()  // Added this line
      card.classList.add('dragging')
      startX = e.type === 'mousedown' ? e.clientX : e.touches[0].clientX
      startY = e.type === 'mousedown' ? e.clientY : e.touches[0].clientY
      
      const transform = window.getComputedStyle(card).getPropertyValue('transform')
      const matrix = new DOMMatrix(transform)
      translateX = matrix.m41
      translateY = matrix.m42
    }

    const onDragMove = (e) => {
      if (!isDragging) return
      
      const currentX = e.type === 'mousemove' ? e.clientX : e.touches[0].clientX
      const currentY = e.type === 'mousemove' ? e.clientY : e.touches[0].clientY
      const deltaX = currentX - startX
      const deltaY = currentY - startY
      
      const rotate = deltaX * 0.1
      
      card.style.transform = `translate(${translateX + deltaX}px, ${translateY + deltaY}px) rotate(${rotate}deg)`
      
      // Updated opacity calculation to match new threshold
      const opacity = Math.max(1 - Math.abs(deltaX) / (this.swipeContainerTarget.offsetWidth * 0.4), 0)
      card.style.opacity = opacity

      this.updateSwipeIndicators(deltaX)
    }

    const onDragEnd = () => {
      if (!isDragging) return
      isDragging = false
      card.classList.remove('dragging')
      
      const transform = window.getComputedStyle(card).getPropertyValue('transform')
      const matrix = new DOMMatrix(transform)
      const currentX = matrix.m41
      
      // Use 0.4 (40%) of container width as threshold for mobile-friendly interaction
      const SWIPE_THRESHOLD = this.swipeContainerTarget.offsetWidth * 0.4
      
      // Add velocity-based dismissal
      const currentTime = Date.now()
      const timeDelta = currentTime - dragStartTime
      const velocity = Math.abs(currentX / timeDelta)
      const VELOCITY_THRESHOLD = 0.75 // pixels per millisecond
      
      if (Math.abs(currentX) > SWIPE_THRESHOLD || velocity > VELOCITY_THRESHOLD) {
        this.dismissCard(card, currentX, matrix.m42)
        this.handleCardAction(card, currentX < 0 ? 'dislike' : 'like')
      } else {
        this.resetCard(card)
      }
    }

    // Add touch event listeners
    card.addEventListener('mousedown', onDragStart)
    card.addEventListener('mousemove', onDragMove)
    card.addEventListener('mouseup', onDragEnd)
    card.addEventListener('mouseleave', onDragEnd)
    
    // Add mouse event listeners
    card.addEventListener('touchstart', onDragStart)
    card.addEventListener('touchmove', onDragMove)
    card.addEventListener('touchend', onDragEnd)
    card.addEventListener('touchcancel', onDragEnd)

  }

  dismissCard(card, currentX, currentY) {
    card.style.transition = 'transform 0.5s, opacity 0.5s'
    card.style.transform = `translate(${currentX < 0 ? -1000 : 1000}px, ${currentY}px) rotate(${currentX < 0 ? -60 : 60}deg)`
    card.style.opacity = '0'
    
    setTimeout(() => {
      card.remove()
      
      if (this.swipeContainerTarget.children.length < 2) {
        this.replenishCards()
      }

      const heartButton = document.getElementById('heartButton')
      if (heartButton) {
        this.updateHeartButtonState(heartButton)
      }
    }, 500)
  }

  replenishCards() {
    const newCards = [...this.originalCards]
    
    newCards.forEach((cardData, index) => {
      const card = this.createCard(cardData, index)
      this.swipeContainerTarget.insertBefore(card, this.swipeContainerTarget.firstChild)
      this.initializeDragListeners(card)
      if (cardData.is_flippable) {
        this.initializeFlipListeners(card)
      }
    })

    Array.from(this.swipeContainerTarget.children).forEach((card, index) => {
      card.style.zIndex = index
    })

    const heartButton = document.getElementById('heartButton')
    if (heartButton) {
      this.updateHeartButtonState(heartButton)
    }
  }

  resetCard(card) {
    card.style.transition = 'transform 0.5s, opacity 0.5s'
    card.style.transform = 'translate(0, 0) rotate(0deg)'
    card.style.opacity = '1'
    
    setTimeout(() => card.style.transition = '', 500)
  }

  handleCardAction(card, action) {
    const cardId = card.dataset.cardId;
    if (action === 'like') {
      return
    }
  }


  addToFavorites(card) {
    const cardId = card.dataset.cardId
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
    
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'X-CSRF-Token': csrf 
      },
      body: JSON.stringify({ card_data: { card_id: cardId } })
    }

    fetch('/add_to_list', requestOptions)
      .then(response => response.json())
      .then(data => {
        // Update favorites value
        this.favoritesValue = data
        
        // Animate the card away (similar to like action)
        // Removed animation of the card away as per instructions
        this.handleCardAction(card, 'like')
      })
      .catch(error => {
        console.error('Error adding to favorites:', error)
      })
  }

  initializeHeartButton() {
    const heartButton = document.getElementById('heartButton')
    if (heartButton) {
      // Initial state check
      this.updateHeartButtonState(heartButton)

      heartButton.addEventListener('click', () => {
        // Create and position the flutter hearts
        heartButton.innerHTML = `<img src="${window.assetPaths.images.heartFilled}" alt="Heart" width="88" height="88">`;
        const rect = heartButton.getBoundingClientRect();
        const flutterHearts = [];
        
        // Create 3 flutter hearts
        for (let i = 0; i < 3; i++) {
          const heart = document.createElement('div');
          heart.innerHTML = `<svg width="88" height="112" viewBox="0 0 88 112" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.4116 50.876C44.5124 50.8485 42.5321 51.399 40.9974 52.9446C39.4508 51.402 37.4515 50.8759 35.6038 50.8759C31.0125 50.8759 26.416 54.6798 26.416 60.0633C26.416 65.254 29.736 69.5753 33.0196 72.5132C34.6811 73.9997 36.3851 75.1798 37.769 75.9925C38.4605 76.3986 39.0838 76.7199 39.5923 76.9436C39.8457 77.0551 40.0835 77.1481 40.2953 77.2153C40.4796 77.2737 40.7393 77.3445 40.9998 77.3445C41.2603 77.3445 41.52 77.2737 41.7042 77.2153C41.916 77.1481 42.1538 77.0551 42.4072 76.9436C42.9158 76.7199 43.5391 76.3986 44.2306 75.9925C45.6145 75.1798 47.3185 73.9997 48.9799 72.5132C52.2636 69.5753 55.5835 65.254 55.5835 60.0633C55.5835 54.6671 50.9764 50.942 46.4116 50.876Z" fill="#F67D6C"/>
          </svg>`;
          heart.className = `flutter-heart flutter-heart-${i}`;
          heart.style.position = 'fixed';
          heart.style.left = `${rect.left + rect.width/2 - 44}px`; // Center horizontally
          heart.style.top = `${rect.top + rect.height/2 - 66}px`;  // Center vertically
          heart.style.zIndex = '1000';
          document.body.appendChild(heart);
          flutterHearts.push(heart);
        }

        // Remove flutter hearts after animation
        flutterHearts.forEach(heart => {
          heart.addEventListener('animationend', () => {
            heart.remove();
          });
        });
      
        // Add to favorites and swipe the card
        const currentCard = this.getCurrentCard();
        if (currentCard) {
          this.addToFavorites(currentCard);
        }
      

      });
    }
  }

  updateHeartButtonState(heartButton) {
    const currentCard = this.getCurrentCard()
    if (currentCard && this.favoritesValue) {
      const cardId = currentCard.dataset.cardId
      const isFavorited = this.favoritesValue.includes(parseInt(cardId))
      
      heartButton.classList.toggle('favorited', isFavorited)
      
      heartButton.innerHTML = `<img src="${isFavorited ? 
        window.assetPaths.images.heartFilled : 
        window.assetPaths.images.heartOutline}" 
        alt="Heart" width="88" height="88">`
    }
  }

  updateSwipeIndicators(deltaX) {
    // TODO: Implement visual indicators for swipe direction
    // e.g., show like/dislike icons based on deltaX
  }

  moveToCard(cardId) {
    console.log('Moving to card:', cardId)
    console.log('Available cards:', this.cardsValue)
    const cardIndex = this.cardsValue.findIndex(card => card.id.toString() === cardId)
    console.log('Found card at index:', cardIndex)
    
    if (cardIndex === -1) {
      console.debug(`Card ${cardId} not found in current chapter`)
      return
    }

    // Remove cards before the target card (0 to cardIndex-1)
    const cardsToRemove = Array.from(this.swipeContainerTarget.children)
      .slice(0, cardIndex)
    console.log('Cards to remove:', cardsToRemove)
    
    cardsToRemove.forEach(card => card.remove())
  }

  initializePreviewMode() {
    // Show subscription prompt after last preview card
    const showSubscriptionPrompt = () => {
      if (this.swipeContainerTarget.children.length === 0) {
        this.subscriptionPromptTarget.classList.remove('hidden');
        this.swipeContainerTarget.classList.add('hidden');
        if (this.previewModePrompt) {
          this.previewModePrompt.classList.add('hidden');
        }
      }
    };

    // Override replenishCards to prevent infinite loop in preview mode
    this.replenishCards = () => {
      // Only show prompt when all preview cards are gone
      showSubscriptionPrompt();
    };

    // Remove the observer since we'll handle everything in replenishCards
  }
}