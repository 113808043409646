// app/javascript/controllers/subscription_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  startCheckout(event) {
    event.preventDefault()
    
    const priceId = this.buttonTarget.dataset.priceId
    const seats = this.buttonTarget.dataset.seats
    
    fetch('/subscriptions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ 
        price_id: priceId,
        quantity: seats 
      })
    })
    .then(response => response.json())
    .then(data => {
      window.location.href = data.url
    })
  }
}