import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]
  
  connect() {
    this.contentTarget.classList.add("mobile-truncate")
    
    // Check if content actually needs truncation
    const lineHeight = parseInt(window.getComputedStyle(this.contentTarget).lineHeight)
    const height = this.contentTarget.scrollHeight
    const lines = height / lineHeight
    
    // If content is 3 lines or less, hide the button
    if (lines <= 3) {
      this.buttonTarget.classList.add('hidden')
    }
  }
  
  toggle(event) {
    event.preventDefault()
    this.contentTarget.classList.toggle("expanded")
    
    if (this.contentTarget.classList.contains("expanded")) {
      this.buttonTarget.textContent = "See less"
    } else {
      this.buttonTarget.textContent = "See more"
    }
  }
} 