import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "content", "item"]

  connect() {
    // Initialize accordion state
    this.isOpen = false
    
    // Get the icon and rotate it initially to point down (closed state)
    const icon = this.buttonTarget.querySelector("[data-accordion-icon]")
    icon.classList.add("rotate-180")
  }

  toggle(event) {
    event.preventDefault()
    this.isOpen = !this.isOpen
    
    // Toggle content visibility
    this.contentTarget.classList.toggle("hidden")
    
    // Rotate arrow icon - remove rotate-180 when open, add it when closed
    const icon = this.buttonTarget.querySelector("[data-accordion-icon]")
    if (this.isOpen) {
      icon.classList.remove("rotate-180")
    } else {
      icon.classList.add("rotate-180")
    }
  }
} 